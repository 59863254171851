* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: white;
}
#container {
  display: flex;
  justify-content: center;
}
main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
header {
  margin: 0 20px;
  position: relative;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
p {
  white-space: pre-line;
}
/* .gallery {
  margin: 0 20px;
  position: relative;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
} */
.logo {
  position: absolute;
  top: 0;
}
.logo__item {
  position: fixed;
  z-index: 50;
  width: 400px;
  height: 100px;
  /* background-image: url('../images/aabel_logo.svg'); */
  /* background-repeat: no-repeat; */
  /* background-size: contain; */
  filter: invert();
  mix-blend-mode: exclusion;
}

.language {
  position: absolute;
  top: 0;
  right: 0px;
  margin-top: 30px;
  z-index: 10;
  display: none;
}
.language__font {
  background-color: transparent;
  font-family: 'Questrial', sans-serif, verdana, sans-serif;
  font-size: 1.5em;
  color: black;
  display: inline;
}
.language__button {
  border: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* nav */

.nav {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 48px;
  right: 0px;
}
.nav__item {
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 50;
  filter: invert(1);
  mix-blend-mode: exclusion;
  background-color: transparent;
  border: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.nav__bar {
  width: 100%;
  height: 4px;
  background-color: black;
  margin-bottom: 9px;
  z-index: 50;
}
.nav__bar-bottom {
  width: 50%;
  margin-left: 50%;
  transition: margin-left 0.2s;
}
.nav__item.active .nav__bar-bottom {
  margin-left: 0%;
}

/* nav overlay */

.overlay {
  position: fixed;
  z-index: 40;
  height: 100%;
  width: 100%;
  left: 100%;
  transition: left 0.2s;
  background-color: black;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.overlay.active {
  left: 0%;
}
.overlay__body {
  width: 100%;
  max-width: 1320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.overlay__top {
  height: 80px;
}
.overlay__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: flex-end;
}
.overlay__content a {
  color: white;
  /* text-align: end; */
  padding: 15px 0;
  font-family: 'Questrial', sans-serif, verdana, sans-serif;
  font-size: 4em;
}

.overlaylanguage {
  margin-top: 20px;
}
.overlaylanguage__font {
  background-color: transparent;
  font-family: 'Questrial', sans-serif, verdana, sans-serif;
  font-size: 2em;
  color: white;
  display: inline;
}
.overlaylanguage__button {
  border: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.overlayfooter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.overlayfooter__contact {
  font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
  font-size: 1.2em;
  position: relative;
  margin-top: 7px;
}
.overlayfooter__contact a {
  color: white;
}
.overlayfooter__contact-mail a {
  text-decoration-line: underline;
}
.overlayfooter__social {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
}
.overlayfooter__socialicon {
  margin: 0px 0px 0 10px;
  color: white;
}

/* projects */

/* projects tabs */

.tabs {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}
.tabs__list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  align-items: baseline;
}
.tabs__item {
  font-family: 'Questrial', sans-serif, verdana, sans-serif;
  font-size: 1.25em;
  cursor: pointer;
  color: black;
}
.tabs__item--back {
  position: relative;
  padding-left: 25px;
  width: 100%;
}
.tabs__arrow {
  position: absolute;
  font-size: 0.7em;
  bottom: 3px;
  left: 0px;
}
.button {
  background-color: transparent;
  border: none;
  text-decoration: none;
  padding: 0 4px;
}
#button-highlight {
  height: 100%;
  margin-left: 0px;
  width: 40px;
  position: absolute;
  background-color: black;
  filter: invert(1);
  mix-blend-mode: exclusion;
  pointer-events: none;
  transition: margin-left 0.3s, width 0.3s;
}

/* projects gallery */

.gallery {
  margin: 0 10px;
  position: relative;
  transition: height 0.5s;
}
.gallery__item {
  margin: 10px;
  position: absolute;
  transition: all 0.5s;
  transform: scale(1);
  overflow: hidden;
}
.gallery__name {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  font-family: 'Questrial', sans-serif, verdana, sans-serif;
  font-size: 1.15em;
  color: white;
}
.gallery__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}
.gallery__image:hover {
  transform: scale(1.1);
}

/* content */

.content {
  margin: 0 20px;
}
.content__title {
  font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
  font-size: 2.5em;
  margin: 25px 0 15px 0;
}
.content__body {
  display: flex;
  flex-direction: column;
}
.content__subtitle-wrapper {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}
.content__subtitle {
  margin: 5px 0;
  font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
  font-size: 1.4em;
}
.content__image--main {
  width: 100vw;
  margin: 10px 0 0 -20px;
}
.content__text-wrapper {
  margin: 5px 0;
}
.content__text {
  font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
  font-size: 1.1em;

  margin: 5px 0;
}
.content__image {
  cursor: zoom-in;
}
.content__image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.content__image--secondary {
  margin: 10px 0;
  width: 100%;
  max-width: 680px;
}
.content__image--secondary:nth-child(even) {
  align-self: flex-end;
}

/* lightbox */

.modal {
  z-index: 60;
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  cursor: zoom-out;
}
.modal__content {
  display: flex;
  justify-content: center;
  position: relative;
}
.modal__slide {
  height: 100vh;
  display: flex;
  align-items: center;
}
.modal__image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.modal__buttons {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1220px;
}
.modal__button {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  text-decoration: none;
  color: white;
}
.modal__button--close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2.5em;
  margin: 0;
  padding: 0 7px;
  cursor: zoom-out;
}
.modal__button--prev {
  font-size: 2em;
  margin: 0;
  padding: 3px 5px;
  cursor: pointer;
}
.modal__button--next {
  font-size: 2em;
  margin: 0;
  padding: 3px 5px;
  cursor: pointer;
}

/* contact */

.contact {
  position: absolute;
  margin-left: 20px;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
}
.contact__title {
  font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
  font-size: 2.5em;
}
.contact__wrapper {
  display: flex;
  flex-direction: column;
}
.contact__info {
  margin: 5px 0;
  font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
  font-size: 1.5em;
}
.contact__info a {
  color: black;
}
.contact__info-mail a {
  text-decoration-line: underline;
}
.contact__socialicon {
  font-size: 1.5em;
  margin: 10px 20px 0 0;
  color: black;
}

/* footer */

.footer {
  margin: auto 20px 20px 20px;
  display: flex;
  flex-direction: column;
}
.footer__icon {
  position: absolute;
  bottom: 7px;
  margin-left: 10px;
}
.footer__icon-right {
  display: none;
}
.footer__wrapper {
  display: flex;
  flex-direction: column;
}
.footer__contact {
  font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
  margin: 4px 0;
  font-size: 1.5em;
  position: relative;
}
.footer__contact a {
  color: black;
}
.footer__contact-info {
  font-size: 1.5em;
}
.footer__contact-mail a {
  text-decoration-line: underline;
}
.footer__social {
  display: flex;
  flex-direction: row;
}
.footer__socialicon {
  font-size: 1.5em;
  margin: 5px 20px 0 0;
  color: black;
}

a {
  text-decoration: none;
}

/* media queries */

/* 640px and up */
@media only screen and (min-width: 640px) {
  .tabs__item {
    font-size: 1.4em;
  }
  .footer {
    flex-direction: row;
    align-items: baseline;
  }
  .footer__icon-down {
    display: none;
  }
  .footer__icon-right {
    display: inline;
  }
  .footer__wrapper {
    flex-direction: column;
    margin-left: 70px;
  }
  .overlayfooter {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* 950px and up */
@media only screen and (min-width: 950px) {
  main {
    max-width: 1320px;
  }
  header {
    margin: 0 50px;
    height: 170px;
  }
  .language {
    display: block;
  }
  .nav {
    top: 76px;
    width: 50px;
  }
  .nav__item {
    width: 50px;
  }
  .nav__bar {
    height: 5px;
    margin-bottom: 12px;
  }
  .logo__item {
    height: 140px;
  }
  .tabs {
    margin: 0 50px;
  }
  .tabs__list {
    width: 600px;
    /* width: 450px; */
  }
  .tabs__item {
    font-size: 1.6em;
  }
  .overlay__body {
    padding: 50px;
  }
  .overlay__top {
    height: 90px;
  }
  .overlay__content a {
    font-size: 6em;
  }
  .overlayfooter__contact {
    font-size: 1.5em;
  }
  .overlayfooter__socialicon {
    font-size: 1.2em;
  }
  .overlaylanguage__font {
    font-size: 3em;
  }
  .gallery {
    margin: 0 25px;
  }
  .gallery__item {
    margin: 25px;
  }
  .content {
    margin: 0 50px;
  }
  .content__title {
    font-family: 'Cutive Mono', sans-serif, verdana, sans-serif;
    font-size: 3em;
    margin: 20px 0;
  }
  .content__body {
    flex-direction: row;
  }
  .content__subtitle-wrapper {
    width: 50%;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 20px;
  }
  .content__subtitle {
    font-size: 1.5em;
  }
  .content__text-wrapper {
    width: 50%;
  }
  .content__text {
    font-size: 1.25em;
  }
  .content__image--main {
    margin: 25px 0 0 -50px;
  }
  .content__image--secondary {
    margin: 30px 0;
  }
  .contact {
    margin-left: 50px;
  }
  .footer {
    margin: auto 50px 50px 50px;
    justify-content: space-between;
  }
  .footer__wrapper {
    /* margin-left: 100px; */
    flex-direction: row;
    width: 70%;
    justify-content: space-between;
  }
  .footer__contact {
    /*  font-size: 1.8em; */
    margin-right: 30px;
  }
  /* .footer__contact-info {
    font-size: 2.3em;
  }
  .footer__contact-mail {
    font-size: 2.3em;
  }
  .footer__icon {
    bottom: 8px;
  }
  .footer__socialicon {
    font-size: 2em;
  } */
}

/* 1320px and up */
@media only screen and (min-width: 1320px) {
  .content__image--main {
    margin: 25px 0 0 0;
    width: 100%;
  }
}
